<!--
 * @Author: wangmengyao wang_my991127@163.com
 * @Date: 2023-09-27 14:10:56
 * @LastEditors: wangmengyao wang_my991127@163.com
 * @LastEditTime: 2023-09-27 16:46:24
 * @FilePath: \cloud_campus_Front-end\school_end\src\components\scrollWrapper\SManagerBaseClassroomScrollWrapper.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="s-manager-base-classroom-wrapper">
		<!-- 教室管理 -->
        <component :is="components[idx]" @setPageInitTabs="setPageInitTabs" @buildingManagement="buildingManagement" @floorManagement="floorManagement"></component>
    </div>
</template>
<script>
import ClassroomManagement from './SManagerBaseClassroom/ClassroomManagement/index.vue';
import BuildingManagement from './SManagerBaseClassroom/BuildingManagement/index.vue'
export default {
    name: "SManagerBaseClassroomScrollWrapper",
    components: {
        ClassroomManagement,
        BuildingManagement
    },
    data () {
        return {
            components: ['ClassroomManagement', 'BuildingManagement'],
            idx: 0,
            type: 'list'
        }
    },
    activated () {
        console.log('activated111111111111111111111');
        this.setGlobalPageType(this.type)
    },
    methods: {
        buildingManagement () {
            this.idx = 1;
            this.setGlobalPageType('detail')
        },
		floorManagement() {
			 this.idx = 0;
             this.setGlobalPageType('list')
		},
        /**
          * @Description: 页面状态 list列表 detail二级页面(form)
          * @DoWhat:一级列表页面（list）显示头部导航面包屑，二级页面（detail）、表单（form）不显示头部导航面包屑
          * @UseScenarios:
          * @Attention:
          * @Author: xwn
          * @Date: 2023-07-20 11:58:06
          */
          setGlobalPageType (type) {
            this.type = type
            this.$eventDispatch('setGlobalPageType', type)
         },
         setPageInitTabs(){
            this.idx = 0;
            this.setGlobalPageType('list')
         }
    }

}
</script>
<style scoped>
    .s-manager-base-classroom-wrapper {
        /* margin: 10px; */
        box-sizing: border-box;
        border-radius: 6px;
    }
</style>
