<template>
	<div class="main-page">
		<!-- 校园OA > 教师考勤 -->
        <tabbed-page
            :tabList="tabList"
            @changeTabs="changeTabs"
        >
        </tabbed-page>
		<div class="page-box">
			<component :is="comps[idx]" @switchClick="switchClick" @checkingInIdChange="checkingInIdChange"
				@routerReturnChange="routerReturnChange" :checkingInId="checkingInId" :routerReturn="routerReturn">
			</component>
		</div>
	</div>
</template>

<script>
    import TabbedPage from './Sub/TabbedPage/index.vue';
    import { tabPageHasAuth } from '@/libs/tabbedPageAuth.js';
	export default {
		name: "SManagerCheckTeacher",
		components: {
			CheckingInDetail: () => import(/* webpackChunkName:'CheckingInDetail' */"./SManagerCheckTeacher/CheckingInDetail.vue"),
			MonthlyStatistics: () => import(/* webpackChunkName:'MonthlyStatistics' */"./SManagerCheckTeacher/MonthlyStatistics.vue"),
			EverydayStatistics: () => import(/* webpackChunkName:'EverydayStatistics' */"./SManagerCheckTeacher/EverydayStatistics.vue"),
            CheckingRecords: () => import(/* webpackChunkName:'CheckingRecords' */"./SManagerCheckTeacher/CheckingRecords.vue"),
            AttendanceStatistics: () => import(/* webpackChunkName:'AttendanceStatistics' */"./SManagerCheckTeacher/AttendanceStatistics.vue"),
            TabbedPage
		},
		data() {
			return {
				currentComponent: "",
				checkingInId: undefined,
				routerReturn: undefined,
                comps: [
                    'CheckingInDetail',
                    'MonthlyStatistics',
                    'EverydayStatistics',
                    'CheckingRecords',
                    'AttendanceStatistics'
                ],
                tabList: [
                    {
                        id: 0,
                        title: '考勤结果',
                        vHasPermi: ['checkattendance:record'],
                        com: 'CheckingInDetail'
                    },
                    {
                        id: 1,
                        title: '月度汇总',
                        vHasPermi: ['checkattendance:monthanalysis'],
                        com: 'MonthlyStatistics'
                    },
                    {
                        id: 2,
                        title: '每日汇总',
                        vHasPermi: ['checkattendance:dailyanalysis'],
                        com: 'EverydayStatistics',
                    },
                    {
                        id: 3,
                        title: '打卡记录',
                        vHasPermi: ['checkattendance:checkrecord'],
                        com: 'CheckingRecords'
                    },
                    {
                        id: 4,
                        title: '考勤统计',
                        vHasPermi: ['checkattendance:analysis'],
                        com: 'AttendanceStatistics'
                    }
                ],
                idx: 0
			}
		},
		created() {
            this.init();
		},
		methods: {
            init () {
                this.tabList = tabPageHasAuth(this.tabList);
                this.comps = this.tabList.map(item => item.com)
                // 跳转到指定tab页
                const id = this.$route.query.id
                if (this.tabList.findIndex(i => i.id === id) > -1) {
                    this.changeTabs(this.$route.query)
                    const query = this._.cloneDeep(this.$route.query)
                    delete query.id
                    // 重新导航到当前路由，并覆盖查询参数
                    this.$router.replace({ path: this.$route.path, query })
                }
            },
            /**
             * @Description: 切换tab
             * @DoWhat:
             * @UseScenarios:
             * @Attention:
             * @Author: 武东超
             * @Date: 2023-08-16 10:01:06
             */
            changeTabs (data) {
                this.tabList.forEach((item, index) => {
                    if (item.id === data.id) {
                        this.$set(item, 'sel', true)
                        this.idx = index;
                    } else {
                        this.$set(item, 'sel', false)
                    }
                })
            },
			switchClick(page) {
				this.currentComponent = page
			},
			checkingInIdChange(id) {
				this.checkingInId = id
			},
			routerReturnChange(path) {
				this.routerReturn = path
			}
		}
	}
</script>

<style lang="scss" scoped>
	.tabs-list {
		height: 48px;
		display: flex;
		padding: 0 30px;
		line-height: 48px;
		align-items: center;
		margin-bottom: 10px;
		background-color: #FFFFFF;
		border-radius: 4px 0px 0px 4px;

		.switch-item {
			color: #333333;
			cursor: pointer;
			font-size: 14px;
			margin-right: 48px;
		}

		.current-item {
            position: relative;
			color: #3C7FFF;

            &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 2px;
                background-color: #3C7FFF;
                border-radius: 1px;
            }
		}
	}

	.page-box {
		margin-right: 10px;
	}
</style>
