<template>
    <div class="import-class-info-wrapper">
        <div class="file-wrapper" v-show="active == '0'">
            <div class="title">
            <img src="../../../../assets/images/import/help.png" alt="导入方法" class="help-img" />导入方法
            </div>
            <div class="template-info">
                <p>下载模版导入模版，根据模版内容填充数据，上传填写好的文件</p>
                <p>(如已有填写好的文件，上传文件皆可)</p>
                <el-button type="text" style="padding: 0 5px" @click="download">
                    <img src="../../../../assets/images/import/download.png" alt="下载模版" class="download-img">
                    下载模板
                </el-button>
            </div>
            <div class="excel-wrapper" :class="[fileName !== '' ? 'active-excel' : 'inactive-excel']">
                <div class="fileName">{{ fileName }}</div>
                <i class="el-icon-circle-close cancel-img" @click="delFile"></i>
            </div>
            <div class="upload-wrapper">
                <el-upload 
                    ref="upload" 
                    :action="action" 
                    :headers="headers" 
                    accept=".xlsx,.xls" 
                    :on-change="uploadChange" 
                    :before-remove="handleRemove" 
                    :with-credentials="true" 
                    :auto-upload="false" 
                    :on-exceed="handleExceed" 
                    :show-file-list="false" 
                    :limit="1">
                    <xk-button 
                        type="primary" 
                        :background-color="'#edf4fa'" 
                        :color="'#3C7FFF'"
                        plain 
                        class="upload-btn"
                    >上传文件</xk-button>
                </el-upload>
                <div>
                    <div class="upload-tips">文件后缀名必须为xls或xlsx（即Excel格式)</div>
                    <div class="upload-tips">文件大小不得大于10M。</div>
                </div>
            </div>
            <div class="import-settings">
                <p>导入设置</p>
                <template>
                    <el-radio v-model="importExcel.errType" label="1">继续执行</el-radio>
                    <el-radio v-model="importExcel.errType" label="2">终止导入</el-radio>
                </template>
                <p class="text">继续执行:遇到错误数据不中断，正确数据导入完成后统一导出错误信息，错误信息不导入</p>
                <p class="text">终止导入:数据必须全部无误才可导入成功，一旦遇到错误即终止。系统导出全部数据，标记错误数据，修改后重新导入。</p>
            </div>
            <div class="btn-wrapper">
                <el-button @click="reset">重置</el-button>
                <el-button type="primary" @click="importSaveFile">导入</el-button>
            </div>
        </div>
        <div v-show="active !== '0'" class="finish-wrapper">
            <i class="el-icon-success" style="color: #36D9B0; font-size: 30px;"></i>
            <p class="success">批量导入完成</p>
            <p class="success-num">本次成功导入{{ successNum }}条数据</p>

            <div class="finish-btn">
                <el-button type="primary"  @click="finish">完成</el-button>
            </div>
        </div>
    </div>
</template>
<script>
    import { uploadAction } from '@/libs/settings';
    import { getToken } from '@/libs/auth';
    export default {
        name: "ImportFloorInfo",
        props: {
            importExcel: {
                type: Object
            },
            active: {
                type: String
            },
            successNum: {
                type: Number
            }
        },
        data () {
            return {
                fileName: ''
            }
        },
        computed: {
            action() {
                return uploadAction
            },
            headers() {
                return {
                    'Authorization': getToken()
                }
            }
        },
        methods: {
            delFile() {
                this.fileName = ''
                this.$refs.upload.clearFiles()
                this.$emit('delFile')
            },
            download() {
                this.$emit('download')
            },
            uploadChange(file) {
                const isImg = ['.xlsx', '.xls'].includes(file.name.substring(file.name.lastIndexOf('.')))
                const isLt10M = file.size / 1024 / 1024 < 10
                const is50 = file.name.length < 30
                if (!isImg) {
                    this.$message.error(`上传文件格式仅支持xlsx和xls!`);
                    this.$refs.upload.clearFiles()
                    return false
                }
                if (!is50) {
                    this.$message.error('上传文件名称不能超过30个字符!');
                    this.$refs.upload.clearFiles()
                    return false
                }
                if (!isLt10M) {
                    this.$message.error(`上传文件的大小不能超过10M!`)
                    this.$refs.upload.clearFiles()
                    return false
                }
                this.fileName = file.name
                this.$emit('uploadChange', file)
            },
            handleRemove () {
                this.$emit('handleRemove')
            },
            handleExceed() {
                this.$message.warning('当前限制上传一个文件')
            },
            importSaveFile () {
                this.$emit('importSaveFile')
            },
            reset () {
                this.delFile()
                this.$emit('resetFile')
            },
            finish () {
                this.delFile()
                this.$emit('finish', 'getNew')
            }
        }
    }
</script>
<style lang="scss" scoped>
    .import-class-info-wrapper {
        .title {
            display: flex;
            justify-content: center;
            align-items: center;

            .help-img {
                width: 16px;
                height: 16px;
                margin-right: 5px;
            }
        }
        .template-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            p {
                line-height: 30px;
            }
            .download-img {
                width: 10px;
                height: 10px;
            }
        }
        .active-excel {
            background-image: url('../../../../assets/images/import/excell.png');
        }
        .inactive-excel {
            background-image: url('../../../../assets/images/import/excel1.png');
        }
        .excel-wrapper {
            position: relative;
            margin: 20px auto;
            width: 480px;
            height: 170px;
            background-color: #f5f7fa;
            background-repeat: no-repeat;
            background-size: 20%;
            background-position: center 20%;
            .fileName {
                position: absolute;
                bottom: 28px;
                left: 50%;
                transform: translate(-50%);
                white-space: nowrap;
                font-size: 18px;
                text-align: center;
                font-weight: bold;
                color: #595959;
            }
            .cancel-img {
                position: absolute;
                top: 10px;
                right: 10px;
                width: 20px;
                height: 20px;
                cursor: pointer;
                font-size: 20px;
                color: #c1cedb;
                &:hover {
                    color: #faafaf;
                }
            }
        }
        .upload-wrapper {
            width: 100%;
            display: flex;
            margin: 0 auto;
            .upload-tips {
                font-size: 12px;
                margin-left: 10px;
            }
        }
        .import-settings {
            box-sizing: border-box;
            padding: 10px;

            p {
                font-size: 14px;
                line-height: 32px;
            }
            .text {
                font-size: 12px;
                line-height: 28px;
            }
        }
        .btn-wrapper {
            box-sizing: border-box;
            padding: 10px;
            display: flex;
            justify-content: flex-end;
        }
    }
    .finish-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .success {
            font-size: 16px;
            color: #36D9B0;
        }
        .success-num {
            line-height: 60px;
            font-size: 14px;
        }
    }
</style>