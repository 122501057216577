<template>
    <div class="floors-forms-wrapper">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="94px" class="common-form" label-position="right">
            <el-form-item label="楼栋类型" prop="buildingType">
                <el-radio-group v-model="ruleForm.buildingType">
                    <el-radio
                        v-for="(item) of classroomType"
                        :key="item.id"
                        :label="item.id"
                    >{{ item.name }}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="楼栋名称" prop="buildingName" placeholder="请输入教室名称">
                <el-input v-model="ruleForm.buildingName" maxlength="15" show-word-limit class="input-width-medium"></el-input>
            </el-form-item>
            <el-form-item label="楼栋描述" prop="buildingDes" placeholder="请输入教室描述">
                <el-input v-model="ruleForm.buildingDes" type="textarea" maxlength="200" show-word-limit class="input-width-medium"></el-input>
            </el-form-item>
            <el-form-item label="楼栋图片" prop="buildingUrl">
                <el-upload class="avatar-uploader" :action="action" :headers="headers"
                	:show-file-list="false" :on-success="handleAvatarSuccess"
                	:before-upload="beforeAvatarUpload">
                	<div class="image-wrapper" >
                		<img v-if="ruleForm.buildingUrl" :src="ruleForm.buildingUrl" class="avatar" alt="">
						<img v-else src="../../../../assets/images/floor_image.png" class="avatar" alt="">
                	</div>

                </el-upload>
				<div style="font-size: 12px;font-family: Microsoft YaHei;font-weight: 400;color: #999999;">推荐尺寸500*500，支持jpg,jpeg,png 类型文件，5M以内。</div>
            </el-form-item>
            <el-form-item v-for="(item, index) in ruleForm.floorList" :key="item.id" :prop="'floorList.' + index + '.floorName'"
				class="level-number" :class="index == 0 ? '': 'level-number1'" :label="index == 0 ? '楼栋层数': '.'"
				:rules="[{required: true, message: '请输入楼层层数', trigger: ['change', 'blur']}]">
				<el-input  v-model="ruleForm.floorList[index].floorName" maxlength="50"  @keyup.native="InputNumber(ruleForm.floorList[index].floorName)" show-word-limit></el-input>
				<el-button @click.prevent="addDomain(item)" size="mini" icon="el-icon-plus" circle v-if="index == 0"></el-button>
				<div v-if="index > 0" style="display: inline-block;">
					<el-button size="mini" icon="el-icon-minus" @click.prevent="removeDomain(item)" circle v-if="item.floorRoomNums == 0" :disabled="false"></el-button>
					<el-button size="mini" icon="el-icon-minus" circle v-else :disabled="true"></el-button>
				</div>

            </el-form-item>
            <el-form-item label="房间数" prop="buildingRoomNum" placeholder="请输入房间数">
                <el-input v-model="ruleForm.buildingRoomNum" maxlength="15" show-word-limit class="input-width-medium"></el-input>
				<div style="font-size: 12px;font-family: Microsoft YaHei;font-weight: 400;color: #999999;">(输入房间数后，限制最多可添加房间数)</div>
            </el-form-item>
            <el-form-item label="排序值" prop="sort" placeholder="请输入排序值">
                <el-input-number
                    v-model="ruleForm.sort"
                    :min="1"
                    :max="100000"
					@change="handlerFloorSortChange"
					class="input-width-medium"
                ></el-input-number>
            </el-form-item>
            <div class="btn-wrapper">
                <el-button @click="resetForm('ruleForm')">重置</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
            </div>
        </el-form>
    </div>
</template>
<script>
	import { uploadAction } from '@/libs/settings';
	import { getToken } from '@/libs/auth';
	export default {
		name: "FloorForms",
		props: {
			ruleForm: {
				type: Object
			},
			rules: {
				type: Object
			},
			classroomType: {
				type: Array
			},
			buildingList: {
				type: Array
			},
			floorList: {
				type: Array
			}
		},
		computed: {
			action() {
			    return uploadAction
			},
			headers() {
			    return {
			        'Authorization': getToken()
			    }
			}
		},
		methods: {
			selectBuilding () {
				this.$emit('selectBuilding')
			},
			submitForm () {
				this.$refs['ruleForm'].validate((valid) => {
					console.log(valid)
					if (valid) {
						this.$emit('submitForm')
					} else {
						return false;
					}
				});
			},
			resetForm () {
				this.$refs['ruleForm'].resetFields();

			},
			// 学校LOGO上传
			handleAvatarSuccess(res, file) {
				if (res.code === '200') {
					this.ruleForm.buildingUrl = res.data.url
				} else {
					this.$message.error(res.data.msg)
				}
			},
			// 上传校验
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';
				const isLt5M = file.size / 1024 / 1024 < 5;

				this.ruleForm.logoState = false;

				if (!isJPG && !isPNG) {
					this.$message.error('上传必须是JPG或PNG格式!');
				}
				if (!isLt5M) {
					this.$message.error('上传图片大小不能超过5MB!');
				}
				return (isJPG || isPNG) && isLt5M ;
			},
			// 楼栋层数动态新增
			addDomain() {
				this.ruleForm.floorList.push({
					id: Date.now(),
				    floorName: '',
					 floorRoomNums:'0'
				});
			},
			// 楼栋层数动态删除
			removeDomain(item) {
				console.log(item)
				let index = this.ruleForm.floorList.indexOf(item)
				if (index !== -1) {
				  this.ruleForm.floorList.splice(index, 1)
				}
			},
			// 验证只能输入数字
			InputNumber (val) {
				// console.log(val)
				if(val) {
					if(!(/(^[1-9]\d*$)/.test(val))){
						this.$alert('楼层层数须为正整数！', '提示', {
						    confirmButtonText: '确定'
						});
					}
				}
			},
			// 排序字段监听
			handlerFloorSortChange () {
			    this.$emit('handlerFloorSortChange')
			}
		}
	}
</script>
<style lang="scss" >

	.floors-forms-wrapper{
		.btn-wrapper {
		    width: 546px;
		    text-align: right;
		}

		.image-wrapper {
			width: 80px;
			height: 80px;
			border: 1px solid #e6e8ed;
		}

		.level-number .el-form-item__content {
			margin-left: 30px !important;
		}
		// 楼栋层数
		.level-number .el-input{
			width: 72%;
			margin-right: 20px;
		}

		.level-number1 .el-form-item__label {
			visibility: hidden;
		}

		// 弹窗单选
		.el-radio {
			width: 72px;
			// padding-top: 12px;
		}


	}

	.add-dialog .el-dialog__body {
		max-height: 650px;
		overflow-y: auto;
	}

</style>
